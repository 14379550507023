import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout/ca"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import ImageGallery from "../components/ui-sections/image-gallery"
import TextSection from "../components/ui-sections/text-section"
import NewsGrid from "../components/news/news-grid"
import previewImage from "../images/espais/principal_finestra.jpg"

const IndexPage = (props) => {
	const heroText = "Benvinguts";
	const textSectionText = "\"A Cal Pere del Maset volem que disfruteu d'una agradable vetllada. T'oferim un producte de temporada, en un entorn de luxe. L'Alt Penedès, Subirats, Sant Pau d'Ordal. Casa nostra, que esperem que sentiu com casa vostra. Benvinguts.\""
	return	(
		  <Layout location={props.location}>
		    <SEO 
		    	title="Pàgina Prinicipal" 
		    	lang="ca"
		    	description={textSectionText}
		    	image={previewImage}
		    />
		    <HeroImage 
		    	titleText={heroText}
		    	image="vinyards" 
		    />
		    <TextSection textSectionText={textSectionText} />
 		 		<Subsection>
 			    <Link to={"/carta"}>
 			    	<Title>Consulta la nova carta</Title>
 			    </Link>
 			    <StaticQuery
 			      query={graphql`
 			        # Replace this comment with the GraphQL query above
 			        {
 			          allHomepageImagesCaYaml {
 			            edges {
 			              node {
 			                title
 			                image {
 			                	id
 			                  childImageSharp {
 			                    fluid {
 			                      aspectRatio
 			                      originalImg
 			                      # Use gatsby-image later for better user experience
 			                      ...GatsbyImageSharpFluid
 			        }}}}}}}
 			      `}
 			      render={data => (
 			        <ImageGallery
 			          itemsPerRow={[1, 3]} // This will be changed to `[2, 3]` later
 			          images={data.allHomepageImagesCaYaml.edges.map(({ node }) => ({
 			          	id: node.image.id,
 			            ...node.image.childImageSharp.fluid,
 			            caption: `${node.title}`,
 			          }))}
 			        />
 			      )}
 			    />
 			  </Subsection>

		 		<Subsection>
			    <Link to={"/noticies"}>
			    	<Title>Consulta les últimes Notícies</Title>
			    </Link>
			    <NewsGrid numberPreviews="2" />
			  </Subsection>
		  </Layout>
		)
}

const Title = styled.h1`
	text-align: center;
`

const Subsection = styled.div`
	margin-top: 60px;
	a {
	  text-decoration: none;
	  color: #000;
	  font-weight: bold;
	}
`

export default IndexPage
